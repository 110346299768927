<template>
  <div class="app-card">
    <!-- search start -->
    <a-form class="ant-advanced-search-form" :form="searchForm">
      <a-row :gutter="24">
        <a-col :span="8">
            <a-form-model-item label="银行账户" prop="bankAccount">
              <a-input v-model="searchForm.bankAccount" />
            </a-form-model-item>
        </a-col>
        <a-col :span="8">
            <a-form-model-item label="开户行" prop="bankName">
              <a-input v-model="searchForm.bankName" />
            </a-form-model-item>
        </a-col>
        <a-col :span="8">
            <a-form-model-item label="银行卡号" prop="bankCard">
              <a-input v-model="searchForm.bankCard" />
            </a-form-model-item>
        </a-col>
      </a-row>
      <a-row>
        <a-col :span="24" :style="{ textAlign: 'right' }">
          <a-button type="primary" @click="searchClick">
            查询
          </a-button>
          <a-button :style="{ marginLeft: '8px' }" @click="cleanSearchClick">
            清除
          </a-button>
          <!-- <a :style="{ marginLeft: '8px', fontSize: '12px' }" @click="expand = !expand">
            {{expand ? '收起' : '展开'}} <a-icon :type="expand ? 'up' : 'down'" />
          </a> -->
        </a-col>
      </a-row>
    </a-form>
    <!-- search end -->
    <!-- table start -->
    <div class="app-card">
      <!-- table 控制 start -->
      <a-row style="padding-bottom:16px" type="flex" justify="end">
        <a-col>
          <a-button icon="plus" type="primary" @click="addAppPaymentAccount" v-if="$root.$data.privateState.action.includes('/AppPaymentAccount/Create') || $root.$data.privateState.role === 'SuperAdmin'">添加付款账户</a-button>
          <a-divider type="vertical" />
          <a-button-group>
            <a-tooltip placement="top">
              <template slot="title">
                <span>密度</span>
              </template>
              <a-dropdown :trigger="['click']">
                <a-button style="color: rgba(0,0,0,.65);" type="link" icon="column-height" />
                <a-menu slot="overlay">
                  <a-menu-item>
                    <a href="javascript:;" :class="tableTdStyle === 'default' ? 'ant-table-td-style-selected': ''" @click="tableTdStyle = 'default'">默认</a>
                  </a-menu-item>
                  <a-menu-item>
                    <a href="javascript:;" :class="tableTdStyle === 'middle' ? 'ant-table-td-style-selected': ''" @click="tableTdStyle = 'middle'">中等</a>
                  </a-menu-item>
                  <a-menu-item>
                    <a href="javascript:;" :class="tableTdStyle === 'small' ? 'ant-table-td-style-selected': ''" @click="tableTdStyle = 'small'">紧凑</a>
                  </a-menu-item>
                </a-menu>
              </a-dropdown>
            </a-tooltip>
            <a-tooltip placement="top">
              <template slot="title">
                <span>全屏</span>
              </template>
              <a-button style="color: rgba(0,0,0,.65);" type="link" :icon="isFullscreen ? 'fullscreen-exit' : 'fullscreen'" @click="() => {screenfull.toggle();isFullscreen=!isFullscreen}"/>
            </a-tooltip>
            <a-tooltip placement="top">
              <template slot="title">
                <span>刷新</span>
              </template>
              <a-button style="color: rgba(0,0,0,.65);" type="link" icon="reload" @click="searchClick" />
            </a-tooltip>
            <a-tooltip placement="top">
              <template slot="title">
                <span>列设置</span>
              </template>
              <a-popover trigger="click" placement="bottom">
                <template slot="content">
                  <a-checkbox-group :value="showColumns" @change="changeColumns">
                    <a-row>
                      <a-col v-for="it of baseColumns" :key="it">
                        <a-checkbox :value="it">
                          {{it}}
                        </a-checkbox>
                      </a-col>
                    </a-row>
                  </a-checkbox-group>
                </template>
                <a-button style="color: rgba(0,0,0,.65);" type="link" icon="setting" />
              </a-popover>
            </a-tooltip>
          </a-button-group>
        </a-col>
      </a-row>
      <!-- table 控制 end -->
      <a-table
        :columns="columns"
        :rowKey="record => record.id"
        :dataSource="tableData"
        :pagination="pagination"
        :loading="loading"
        @change="handleTableChange"
        bordered
        :size="tableTdStyle"
      >
        <template #createdAt="createdAt">
          {{moment(createdAt).format('YYYY-MM-DD HH:mm:ss')}}
        </template>
        <template #type="type">
          <a-tag color="blue" v-if="type == 'company'">公户</a-tag>
          <a-tag color="purple" v-else-if="type == 'private'">私户</a-tag>
          <a-tag v-else>异常</a-tag>
        </template>
        <template #action="data">
          <a-button type="link" @click="toEdit(data)" block>编辑</a-button>
          <a-popconfirm
            title="是否确认删除该条?"
            @confirm="toDelete(data)"
            okText="是"
            cancelText="否"
          >
            <a-button type="link" block>删除</a-button>
          </a-popconfirm>
          <a-popover title="请选择导出类型"  trigger="click" v-if="$root.$data.privateState.action.includes('/AppPaymentAccount/PaymentVoucherExcel') || ['SuperAdmin', 'Admin'].includes($root.$data.privateState.role)">
            <a-row slot="content" :gutter="[8, 24]" justify="center">
              <a-col style="text-align: center;">
                <a-tag color="orange" @click="makePaymentVoucher(data, 'await')">待结算</a-tag>
              </a-col>
              <a-col style="text-align: center;">
                <a-tag color="green" @click="makePaymentVoucher(data, 'done')">已结算</a-tag>
              </a-col>
            </a-row>
            <a-button icon="cloud-download" type="link" >生成付款结算表</a-button>
          </a-popover>
          
        </template>
      </a-table>
    </div>
    
    <a-modal width="80%" :title="createAndEditModalTitle" v-model="createAndEditShow" @ok="createAndEditModalOk" >
      <a-form-model ref="createAndEditForm" :model="createAndEditForm" :rules="createAndEditFormRules" v-bind="formLayout">
        <a-form-model-item label='银行账户' prop="bankAccount">
          <a-input v-model="createAndEditForm.bankAccount" />
        </a-form-model-item>
        <a-form-model-item label='开户行' prop="bankName">
          <a-input v-model="createAndEditForm.bankName" />
        </a-form-model-item>
        <a-form-model-item label='银行卡号' prop="bankCard">
          <a-input :disabled="this.nowFormType === 'edit'" v-model="createAndEditForm.bankCard" />
        </a-form-model-item>
        <a-form-model-item label='类型' prop="type">
          <a-select v-model="createAndEditForm.type" style="width: 100%">
            <a-select-option value="company">
              公户
            </a-select-option>
            <a-select-option value="private">
              私户
            </a-select-option>
          </a-select>
        </a-form-model-item>
      </a-form-model>
    </a-modal>

    <a-modal
      :width="200"
      :visible="modalLoading"
      :closable="false"
      :maskClosable="false"
      :footer="null"
    >
      <a-row>
        <a-col style="text-align: center;">
          <a-icon type="loading" :style="{ fontSize: '36px', color: '#1890ff' }" />
        </a-col>
        <a-col style="text-align: center;color:#1890ff;padding-top:10px;">
          {{modalLoadingMessage}}
        </a-col>
      </a-row>
      
    </a-modal>
    <a-modal
      v-model="showExportPaymentVoucherExcelDateSelect"
      title="导出付款结算表(时间范围为转款时间)"
      @ok="exportPaymentVoucherExcel"
      @cancel="showExportPaymentVoucherExcelDateSelect = false"

    >
      <a-row>
        <a-range-picker style="width:100%" :disabled-date="c => c > moment().endOf('day')" v-model="exportPaymentVoucherExcelDate" />
      </a-row>
    </a-modal>
  </div>
</template>

<script>
import licia from 'licia'
import moment from 'moment'
import DownloadJS from "downloadjs";
import { AppPaymentAccount } from '@/api'
import { aliveVM, mixinTableList } from '@/utils/mixin'

const INIT_SEARCH_FORM = '{"bankCard":"","bankName":"","bankAccount":""}'

const columns = [
  {
    title: '银行账户',
    dataIndex: 'bankAccount',
  },
  {
    title: '开户行',
    dataIndex: 'bankName',
  },
  {
    title: '银行卡号',
    dataIndex: 'bankCard',
  },
  {
    title: '类型',
    dataIndex: 'type',
    scopedSlots: { customRender: 'type' },
  },
  {
    title: '添加或最后编辑用户',
    dataIndex: 'createUserName',
  },
  {
    title: '建立时间',
    width: 150,
    dataIndex: 'createdAt',
    scopedSlots: { customRender: 'createdAt' },
  },
  {
    title: '操作',
    key: 'operation',
    width: 130,
    scopedSlots: { customRender: 'action' },
  }
]

export default {
  name: 'AppPaymentAccount',
  mixins: [aliveVM, mixinTableList],
  async mounted () {
    const baseColumns = []
    this.$set(this, 'columns', JSON.parse(JSON.stringify(columns)))
    for (const it of columns) {
      baseColumns.push(it.title)
    }
    this.fetch()
  },
  data () {
    return {
      modalLoading: false,
      modalLoadingMessage: '正在处理数据...',
      layout: {
        labelCol: { span: 8 },
        wrapperCol: { span: 16 },
      },
      moment,
      tableListApi: `${AppPaymentAccount}/TableList`,
      searchParams: {},
      searchForm: JSON.parse(INIT_SEARCH_FORM),
      columns: [],
      baseColumns: [],
      // other
      formLayout: {
        labelCol: { span: 4 },
        wrapperCol: { span: 14 },
      },
      createAndEditModalTitle: '添加付款账户',
      createAndEditShow: false,
      createAndEditForm: {
        bankCard: '',
        bankName: '',
        bankAccount: '',
        type: '',
      },
      createAndEditFormRules: {
        bankCard: [{ required: true, message: "请填写", trigger: "blur" }],
        bankName: [{ required: true, message: "请填写", trigger: "blur" }],
        bankAccount: [{ required: true, message: "请填写", trigger: "blur" }],
        type: [{ required: true, message: "请选择", trigger: "change" }]
      },
      nowFormType: '',
      nowEditUuid: '',
      // 付款结算表
      exportTypeSelectShow: false,
      makeType: '',
      bankCard: '',
      bankAccount: '',
      showExportPaymentVoucherExcelDateSelect: false,
      exportPaymentVoucherExcelDate: []
    }
  },
  methods: {
    changeColumns (v) {
      const c = [];
      for (const it of columns) {
        if (v.includes(it.title)) {
          c.push(it)
        }
      }
      this.$set(this, 'columns', c)
    },
    async reload() {
      this.$set(this, 'searchParams', {})
      
      this.searchForm.resetFields()
      await this.fetch()
    },
    async cleanSearchClick () {
      this.$set(this, 'searchParams', {})
      
      this.$set(this, 'searchForm', JSON.parse(INIT_SEARCH_FORM))
      await this.fetch()
    },
    async searchClick () {
      this.pagination.current = 1
      const searchForm = JSON.parse(JSON.stringify(this.searchForm))
      let searchParams = {}
      for (const k of Object.keys(searchForm)) {
        const it = searchForm[k]
        if (k === 'startAt' || k === 'endAt') {
          if (!it || it.length < 1) continue
          it[0] = moment(it[0]).format('YYYY-MM-DD') + " 00:00:00"
          it[1] = moment(it[1]).format('YYYY-MM-DD') + " 23:59:59"
          searchParams[k] = it
        }
        if (licia.isNum(it)) {
          searchParams[k] = it
          continue
        }
        if (!licia.isEmpty(it)) {
          searchParams[k] = it
        }
      }
      if (licia.isEmpty(searchParams)) {
        searchParams = {}
      }
      
      this.$set(this, 'searchParams', searchParams)
      this.fetch()
    },
    // other
    async addAppPaymentAccount() {
      this.nowFormType = 'create'
      this.createAndEditModalTitle = '添加付款账户'
      this.$set(this, 'createAndEditForm', {
        bankCard: '',
        bankName: '',
        bankAccount: '',
        type: ''
      })
      this.createAndEditShow = true
    },
    createAndEditModalOk() {
      this.$refs.createAndEditForm.validate(async (valid) => {
        if (!valid) {
          this.$message.error("请检查填写内容");
          return;
        }
        if (this.nowFormType === 'create') {
          const apiRes = await this.$axios
          .post(`${AppPaymentAccount}/Create`, this.createAndEditForm)
          .catch((e) => {
            return {
              status: false,
              message: e.message.indexOf("401") > -1 ? "登录超时" : e.message,
            };
          });
          if (!apiRes.status) {
            this.$message.error(apiRes.message);
            this.sending = false;
            return;
          }
        } else {
          const apiRes = await this.$axios
          .post(`${AppPaymentAccount}/Edit?bankCard=${this.nowEditUuid}`, this.createAndEditForm)
          .catch((e) => {
            return {
              status: false,
              message: e.message.indexOf("401") > -1 ? "登录超时" : e.message,
            };
          });
          if (!apiRes.status) {
            this.$message.error(apiRes.message);
            this.sending = false;
            return;
          }
        }
        
        this.$message.success(this.nowFormType === 'create' ? '添加成功' : '编辑成功')
        this.createAndEditShow = false
        this.fetch()
      })
    },
    async toEdit (data) {
      this.nowFormType = 'edit'
      this.createAndEditModalTitle = '编辑付款账户i'
      this.nowEditUuid = data.bankCard
      this.createAndEditForm.bankCard = data.bankCard
      this.createAndEditForm.bankName = data.bankName
      this.createAndEditForm.bankAccount = data.bankAccount
      this.createAndEditForm.type = data.type
      this.createAndEditShow = true
    },
    async toDelete (data) {
      this.loading = true
      const res = await this.$axios.delete(`${AppPaymentAccount}/Delete?bankCard=${data.bankCard}`)
        .catch(e => {
          return {
            status: false,
            message: e.message.indexOf('401') > -1 ? '登录超时' : e.message
          }
        })
      this.loading = false
      if (!res.status) {
        this.$message.error(res.message)
        return
      }
      this.fetch()
    },
    // 详情
    async toDetail (data) {
      const detail = await this.getDetail(data.uuid)
      if (detail === null) return
      this.$set(this, 'wikiDetail', detail)
      this.wikiDetailShow = true
    },
    // 付款凭证
    makePaymentVoucher (data, makeType) {
      
      this.nowEditUuid = data.uuid
      this.makeType = makeType
      this.bankCard = data.bankCard
      this.bankAccount = data.bankAccount
      this.exportPaymentVoucherExcelDate = []
      this.showExportPaymentVoucherExcelDateSelect = true
    },
    async exportPaymentVoucherExcel()
    {
      if (!this.exportPaymentVoucherExcelDate || this.exportPaymentVoucherExcelDate.length < 2) {
        this.$message.error('请选择导出月份');
        return;
      }
      const transferDateTime = [this.exportPaymentVoucherExcelDate[0].format('YYYY-MM-DD') + ' 00:00:00', this.exportPaymentVoucherExcelDate[1].format('YYYY-MM-DD')  + ' 23:59:59'];
      
      this.sending = true
      this.sendingHint = '正在获取数据'
      const apiRes = await this.$axios.post(`${AppPaymentAccount}/PaymentVoucherExcel?type=${this.makeType}&bankCard=${this.bankCard}`, {
        transferDateTime
      }).catch(e => {
        return {
          status: false,
          message: e.message.indexOf('401') > -1 ? '登录超时' : e.message
        }
      })
      this.sending = false
      if (!apiRes.status) {
        this.sending = false
        this.$message.error(apiRes.message)
        return
      }
      this.showExportPaymentVoucherExcelDateSelect = false
      DownloadJS(window.atob(apiRes.data), `${this.bankAccount}_${transferDateTime[0]}_${transferDateTime[1]}_付款结算表数据.xlsx`)
    }
  }
}
</script>

<style>

</style>